/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// fix bug on gatsby that retains scroll position on navigate
// https://github.com/gatsbyjs/gatsby/issues/19488
exports.onRouteUpdate = () => {
  var body = document.body
  body.scrollTop = 0
}

exports.shouldUpdateScroll = () => {
  return false
}

exports.onInitialClientRender = () => {
  window.scrollTo(0, 0)
  if (window) {
    window._isProduction = !location.hostname.includes('localhost')
  }
}
